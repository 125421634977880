


textarea.commonTextArea {
    width: 100%;
    padding: 5px 5px;
    margin: 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 0.9rem;
    font-weight: 600;
}

.commonTextAreaLabel {
    font-weight: 600;
    /* color: white; */
    line-height: 1.2rem;
}

textarea.grayColor {
    background: #eee;
    border: 1px solid #eee;
}
