.displayNone {
    display: none !important;
}
.select_chevron {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.select-box {
    position: relative;
    display: flex;
    width: 250px;
    flex-direction: column;
  }
  .select_search_dropdown_container_w {
    position: absolute;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    top: 40px;
    left: 0;
    display: none;
    flex-direction: column;
    background: white;
    
    min-width: 100%;
    opacity: 0;
  
    border-radius: 8px;
    overflow: hidden;
  
    order: 1;
    z-index: 1000;
    color: rgb(56, 52, 52);
  }
  .select-box .options-container {
    background: white;
    
    /* width: 100%; */
    opacity: 0;
  
    /* border-radius: 8px; */
    overflow: hidden;
  
    order: 1;
    color: rgb(56, 52, 52);
   
  }
  .select-box .option, .selected {
    padding: 5px 12px;
    cursor: pointer;
  }
  .select-box .option, .selected {
    padding: 8px 12px;
  }
  .select-box .option .radio {
    display: none;
  }
  .selected {
    background: white;
    border-radius: 8px;
    margin-bottom: 8px;
    color: rgb(56, 52, 52);
    position: relative;
    order: 0;
    /* box-shadow: 0px 10px 70px 4px rgba(0, 0, 0, 0.13); */
  }
  
  .selected:after{ 
    /* content: '\f106'; */
      position: absolute;
      right: 20px;
    
      /* font-family: 'Font Awesome 5 Free'; */
      font-weight: 700;
    
      color: black;
      transform: rotate(180deg);
      transition: all .4s;
    
  }  
  .select-box .option:hover {
    background: #535bff33;
    border-radius: 0px;
    color: #41436a;
   
  }
  .select-box .option input:hover{
      font-weight: 700px;
  }
  
  .search-box {
    position: relative;
    display: flex;
    padding: 10px 5px;
    /* height: 100px !important; */
  }
  .search-box input {
    width: 100%;
    padding: 5px 5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    /* position: absolute; */
    position: relative;
    outline: none;
    opacity: 1;
    border: 1px solid #ccc;
    outline-offset: -10px;
    border-radius: 5px;
    z-index: 100;
   
    /* border: none; */
  }
  /* .search-box input:focus {
    outline: none;
  } */
  #option1{
    /* margin-top: 45px; */
  }
  /* .select_search_dropdown_container_w {
    display: flex;
    flex-direction: column;
    background: white;
    
    width: 100%;
    opacity: 0;
  
    border-radius: 8px;
    overflow: hidden;
  
    order: 1;
    color: rgb(56, 52, 52);
  } */
  .select-box .options-container.active
   {
    max-height: 170px;
    /* min-height: 100px; */
    opacity: 1;
   overflow-y: auto;
    transition: all 0.4s;
    
    /* margin-top: 54px; */
  }
  .select-box .select_search_dropdown_container_w.active {
    opacity: 1;
    display: flex;
    overflow-y: auto;
    transition: all 0.4s;
  }
  .select-box .options-container.active ~ .selected::after {
    transform: rotateY(180deg);
    /* top: -6px; */
  }
  .select-box .options-container::-webkit-scrollbar {
    width: 7px;
    background: #888;
    border-radius:  8px ;
    height: 1px;
  }
  .select-box .options-container::-webkit-scrollbar-thumb {
    background: #464749;
    border-radius: 8px 8px 8px 8px;
  
  }
  .select-box .options-container.active > .search-box input {
    opacity: 1;
    pointer-events: auto;
  }
