/* ///////////////////////////////////////////////////// */
.modal_dark_screen_w {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    /* background: rgba(0, 0, 0, 0.3); */
    background: rgba(2, 48, 71, 0.8);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
}

.modal_content_box_w {
    position: relative;
    height: fit-content;
    min-width: 650px;
    max-width: 90%;
    background: white;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}



/* The container_checkbo_select_w */
.container_checkbo_select_w {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: transparent;
  }
  .mb0w {
    margin-bottom: 0 !important;
  }
  /* Hide the browser's default checkbox */
  .container_checkbo_select_w > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark_checkbo_select_w {
    position: absolute;
    /* top: ; */
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #777;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f0f0f0;
    /* background-color: #eee; */
  }
  .checkmark_checkbo_select_w.bg_white {
    background: #fff;
    border: 1px solid #41436a;
  }
  
  /* On mouse-over, add a grey background color */
  .container_checkbo_select_w:hover > input ~ .checkmark_checkbo_select_w {
    background-color: #eee;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container_checkbo_select_w > input:checked ~ .checkmark_checkbo_select_w {
    background-color: #41436a;
    border: 1px solid #41436a;
  }
  
  /* Create the checkmark_checkbo_select_w/indicator (hidden when not checked) */
  .checkmark_checkbo_select_w:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark_checkbo_select_w when checked */
  .container_checkbo_select_w > input:checked ~ .checkmark_checkbo_select_w:after {
    display: block;
  }
  
  /* Style the checkmark_checkbo_select_w/indicator */
  .container_checkbo_select_w .checkmark_checkbo_select_w:after {
    left: 7.5px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }